import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/marcodamm/personal/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Reading List" keywords={['gatsby', 'application', 'react']} mdxType="SEO" />
    <h1>{`My to-read and have-read list`}</h1>
    <p>{`Here is a list of fiction and
non-fiction literature I enjoyed.`}</p>
    <p>{`I like to keep track of things I have read, so
this list is mostly for my own reference, but
feel free to look for some inspiration. The
`}<em parentName="p">{`fiction`}</em>{` section mostly consists of
science-fiction, horror and fantasy novels, but
you will find some more grounded material as
well.`}</p>
    <h2>{`Fiction`}</h2>
    <p>{`Aldous Huxley`}</p>
    <ul>
      <li parentName="ul">{`Brave New World`}</li>
    </ul>
    <p>{`Ann Leckie`}</p>
    <ul>
      <li parentName="ul">{`Imperial Radch trilogy`}
        <ul parentName="li">
          <li parentName="ul">{`Ancillary Justice`}</li>
          <li parentName="ul">{`Ancillary Sword`}</li>
          <li parentName="ul">{`Ancillary Mercy`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Anne Rice`}</p>
    <ul>
      <li parentName="ul">{`Interview with the Vampire`}</li>
      <li parentName="ul">{`The Vampire Lestat`}</li>
      <li parentName="ul">{`The Queen of the Damned`}</li>
      <li parentName="ul">{`The Tale of the Body Thief`}</li>
      <li parentName="ul">{`Memnoch the Devil`}</li>
      <li parentName="ul">{`The Vampire Armand`}</li>
      <li parentName="ul">{`Merrick`}</li>
      <li parentName="ul">{`Blood and Gold`}</li>
      <li parentName="ul">{`Blackwood Farm`}</li>
      <li parentName="ul">{`Blood Canticle`}</li>
      <li parentName="ul">{`Prince Lestat`}</li>
      <li parentName="ul">{`Prince Lestat and the Realms of Atlantis`}</li>
    </ul>
    <p>{`Arkady and Boris Strugatsky`}</p>
    <ul>
      <li parentName="ul">{`Roadside Picknick`}</li>
    </ul>
    <p>{`Arthur C. Clarke`}</p>
    <ul>
      <li parentName="ul">{`2001: A Space Odyssey`}</li>
      <li parentName="ul">{`2010: Odyssey Two`}</li>
    </ul>
    <p>{`Barry B. Longyear`}</p>
    <ul>
      <li parentName="ul">{`Enemy Mine`}</li>
    </ul>
    <p>{`Bram Stoker`}</p>
    <ul>
      <li parentName="ul">{`Dracula`}</li>
    </ul>
    <p>{`Bruce Sterling`}</p>
    <ul>
      <li parentName="ul">{`Islands in the Net`}</li>
      <li parentName="ul">{`Mirrorshades`}</li>
      <li parentName="ul">{`Schismatrix`}</li>
    </ul>
    <p>{`Charles Dickens`}</p>
    <ul>
      <li parentName="ul">{`The Adventures of Oliver Twist`}</li>
      <li parentName="ul">{`Great Expectations`}</li>
    </ul>
    <p>{`Charles Stross`}</p>
    <ul>
      <li parentName="ul">{`Accelerando`}</li>
    </ul>
    <p>{`Clive Barker`}</p>
    <ul>
      <li parentName="ul">{`The Hellbound Heart`}</li>
      <li parentName="ul">{`Cabal`}</li>
      <li parentName="ul">{`Books of Blood`}</li>
    </ul>
    <p>{`Dmitry Glukhovsky`}</p>
    <ul>
      <li parentName="ul">{`Metro 2033`}</li>
      <li parentName="ul">{`Metro 2034`}</li>
      <li parentName="ul">{`Metro 2035`}</li>
    </ul>
    <p>{`Douglas Adams`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`HGttG Series`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Hitchhiker's Guide to the Galaxy`}</li>
          <li parentName="ul">{`The Restaurant at the End of the Universe`}</li>
          <li parentName="ul">{`Life, the Universe and Everything`}</li>
          <li parentName="ul">{`So Long and Thanks for All the Fish`}</li>
          <li parentName="ul">{`Mostly Harmless`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Dirk Gently Series`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Dirk Gently's Holistic Detective Agency`}</li>
          <li parentName="ul">{`The Long Dark Tea-Time of the Soul`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Edgar Allan Poe`}</p>
    <ul>
      <li parentName="ul">{`The Raven`}</li>
    </ul>
    <p>{`Frank and Brian Herbert with K.J. Anderson`}</p>
    <ul>
      <li parentName="ul">{`Dune Series`}
        <ul parentName="li">
          <li parentName="ul">{`Dune`}</li>
          <li parentName="ul">{`Dune Messiah`}</li>
          <li parentName="ul">{`Children of Dune`}</li>
          <li parentName="ul">{`God Emperor of Dune`}</li>
          <li parentName="ul">{`Heretics of Dune`}</li>
          <li parentName="ul">{`Chapterhouse: Dune`}</li>
          <li parentName="ul">{`House Atreides`}</li>
          <li parentName="ul">{`House Harkonnen`}</li>
          <li parentName="ul">{`House Corrino`}</li>
          <li parentName="ul">{`The Butlerian Jihad`}</li>
          <li parentName="ul">{`The Machine Crusade`}</li>
          <li parentName="ul">{`The Battle of Corrin`}</li>
          <li parentName="ul">{`Hunters of Dune`}</li>
          <li parentName="ul">{`Sandworms of Dune`}</li>
        </ul>
      </li>
    </ul>
    <p>{`George Alec Effinger`}</p>
    <ul>
      <li parentName="ul">{`Marîd Audran Series`}
        <ul parentName="li">
          <li parentName="ul">{`When Gravity Fails`}</li>
          <li parentName="ul">{`A Fire in the Sun`}</li>
          <li parentName="ul">{`The Exile Kiss`}</li>
          <li parentName="ul">{`The Audran Sequence`}</li>
          <li parentName="ul">{`Budayeen Nights`}</li>
        </ul>
      </li>
    </ul>
    <p>{`George Orwell`}</p>
    <ul>
      <li parentName="ul">{`Nineteen Eighty-Four`}</li>
    </ul>
    <p>{`H.G. Wells`}</p>
    <ul>
      <li parentName="ul">{`The Invisible Man`}</li>
      <li parentName="ul">{`The Time Machine`}</li>
      <li parentName="ul">{`The War of the Worlds`}</li>
    </ul>
    <p>{`H.P. Lovecraft`}</p>
    <ul>
      <li parentName="ul">{`At the Mountains of Madness`}</li>
      <li parentName="ul">{`Azathoth`}</li>
      <li parentName="ul">{`Dagon`}</li>
      <li parentName="ul">{`From Beyond`}</li>
      <li parentName="ul">{`Herbert West – Reanimator`}</li>
      <li parentName="ul">{`Nyarlathotep`}</li>
      <li parentName="ul">{`Pickman's Model`}</li>
      <li parentName="ul">{`The Call of Cthulhu`}</li>
      <li parentName="ul">{`The Case of Charles Dexter Ward`}</li>
      <li parentName="ul">{`The Cats of Ulthar`}</li>
      <li parentName="ul">{`The Colour Out of Space`}</li>
      <li parentName="ul">{`The Dunwich Horror`}</li>
      <li parentName="ul">{`The Moon-Bog`}</li>
      <li parentName="ul">{`The Music of Erich Zann`}</li>
      <li parentName="ul">{`The Nameless City`}</li>
      <li parentName="ul">{`The Rats in the Walls`}</li>
      <li parentName="ul">{`The Shadow Out of Time`}</li>
      <li parentName="ul">{`The Shadow Over Innsmouth`}</li>
      <li parentName="ul">{`The Thing on the Doorstep`}</li>
      <li parentName="ul">{`The Unnamable`}</li>
      <li parentName="ul">{`The Whisperer in Darkness`}</li>
      <li parentName="ul">{`What the Moon Brings`}</li>
    </ul>
    <p>{`Isaac Asimov`}</p>
    <ul>
      <li parentName="ul">{`Foundation`}</li>
      <li parentName="ul">{`I, Robot`}</li>
      <li parentName="ul">{`Robot Dreams`}</li>
      <li parentName="ul">{`The Edge of Tomorrow`}</li>
      <li parentName="ul">{`The Positronic Man`}</li>
    </ul>
    <p>{`James S.A. Corey`}</p>
    <ul>
      <li parentName="ul">{`Leviathan Wakes`}</li>
      <li parentName="ul">{`Caliban's War`}</li>
      <li parentName="ul">{`Abaddon's Gate`}</li>
      <li parentName="ul">{`Cibola Burn`}</li>
      <li parentName="ul">{`Nemesis Games`}</li>
      <li parentName="ul">{`Babylon's Ashes`}</li>
    </ul>
    <p>{`Jeff Vandermeer`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Southern Reach Trilogy`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Annihilation`}</li>
          <li parentName="ul">{`Authority`}</li>
          <li parentName="ul">{`Acceptance`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Other Stories`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Borne`}</li>
        </ul>
      </li>
    </ul>
    <p>{`John Scalzi`}</p>
    <ul>
      <li parentName="ul">{`Fuzzy Nation`}</li>
      <li parentName="ul">{`Redshirts`}</li>
    </ul>
    <p>{`Jules Verne`}</p>
    <ul>
      <li parentName="ul">{`Around the World in Eighty Days`}</li>
      <li parentName="ul">{`Journey to the Center of the Earth`}</li>
      <li parentName="ul">{`Twenty Thousand Leagues Under the Search`}</li>
    </ul>
    <p>{`J.R.R. Tolkien`}</p>
    <ul>
      <li parentName="ul">{`The Hobbit`}</li>
      <li parentName="ul">{`The Lord of the Rings`}</li>
    </ul>
    <p>{`Mark Z. Danielewski`}</p>
    <ul>
      <li parentName="ul">{`House of Leaves`}</li>
    </ul>
    <p>{`Mary Shelley`}</p>
    <ul>
      <li parentName="ul">{`Frankenstein; or, The Modern Prometheus`}</li>
    </ul>
    <p>{`Max Brooks`}</p>
    <ul>
      <li parentName="ul">{`World War Z`}</li>
    </ul>
    <p>{`Neal Stephenson`}</p>
    <ul>
      <li parentName="ul">{`Snow Crash`}</li>
    </ul>
    <p>{`Oscar Wilde`}</p>
    <ul>
      <li parentName="ul">{`The Picture of Dorian Gray`}</li>
    </ul>
    <p>{`Philip K. Dick`}</p>
    <ul>
      <li parentName="ul">{`A Scanner Darkly`}</li>
      <li parentName="ul">{`Do Androids Dream of Electric Sheep?`}</li>
      <li parentName="ul">{`The Minority Report`}</li>
      <li parentName="ul">{`Ubik`}</li>
      <li parentName="ul">{`We Can Remember It For You Wholesale`}</li>
    </ul>
    <p>{`R.A. Salvatore`}</p>
    <ul>
      <li parentName="ul">{`Homeland`}</li>
      <li parentName="ul">{`Exile`}</li>
      <li parentName="ul">{`Sojourn`}</li>
    </ul>
    <p>{`Richard K. Morgan`}</p>
    <ul>
      <li parentName="ul">{`Takeshi Kovacs Series`}
        <ul parentName="li">
          <li parentName="ul">{`Altered Carbon`}</li>
          <li parentName="ul">{`Broken Angels`}</li>
          <li parentName="ul">{`Woken Furies`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Richard Matheson`}</p>
    <ul>
      <li parentName="ul">{`I Am Legend`}</li>
    </ul>
    <p>{`Robert Silverberg`}</p>
    <ul>
      <li parentName="ul">{`The Insidious Invaders`}</li>
    </ul>
    <p>{`Rudy Rucker`}</p>
    <ul>
      <li parentName="ul">{`Ware Tetralogy`}
        <ul parentName="li">
          <li parentName="ul">{`Software`}</li>
          <li parentName="ul">{`Wetware`}</li>
          <li parentName="ul">{`Freeware`}</li>
          <li parentName="ul">{`Realware`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Terry Pratchett`}</p>
    <ul>
      <li parentName="ul">{`Discworld Series`}
        <ul parentName="li">
          <li parentName="ul">{`Carpe Jugulum`}</li>
          <li parentName="ul">{`Equal Rites`}</li>
          <li parentName="ul">{`Eric`}</li>
          <li parentName="ul">{`Feet of Clay`}</li>
          <li parentName="ul">{`Going Postal`}</li>
          <li parentName="ul">{`Guards! Guards!`}</li>
          <li parentName="ul">{`Interesting Times`}</li>
          <li parentName="ul">{`Jingo`}</li>
          <li parentName="ul">{`Lords and Ladies`}</li>
          <li parentName="ul">{`Making Money`}</li>
          <li parentName="ul">{`Maskerade`}</li>
          <li parentName="ul">{`Men at Arms`}</li>
          <li parentName="ul">{`Monstrous Regiment`}</li>
          <li parentName="ul">{`Mort`}</li>
          <li parentName="ul">{`Moving Pictures`}</li>
          <li parentName="ul">{`Night Watch`}</li>
          <li parentName="ul">{`Pyramids`}</li>
          <li parentName="ul">{`Reaper Man`}</li>
          <li parentName="ul">{`Small Gods`}</li>
          <li parentName="ul">{`Snuff`}</li>
          <li parentName="ul">{`Sourcery`}</li>
          <li parentName="ul">{`The Amazing Maurice and his Educated Rodents`}</li>
          <li parentName="ul">{`The Colour of Magic`}</li>
          <li parentName="ul">{`The Fifth Elephant`}</li>
          <li parentName="ul">{`The Last Continent`}</li>
          <li parentName="ul">{`The Last Hero`}</li>
          <li parentName="ul">{`The Light Fantastic`}</li>
          <li parentName="ul">{`The Soul Music`}</li>
          <li parentName="ul">{`The Truth`}</li>
          <li parentName="ul">{`TheHogfather`}</li>
          <li parentName="ul">{`Thief of Times`}</li>
          <li parentName="ul">{`Thud!`}</li>
          <li parentName="ul">{`Unseen Academicals`}</li>
          <li parentName="ul">{`Wintersmith`}</li>
          <li parentName="ul">{`Witches Abroad`}</li>
          <li parentName="ul">{`Wyrd Sisters`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Robert A. Heinlein`}</p>
    <ul>
      <li parentName="ul">{`Starship Troopers`}</li>
      <li parentName="ul">{`Stranger in a Strange Land`}</li>
    </ul>
    <p>{`Robert Louis Stevenson`}</p>
    <ul>
      <li parentName="ul">{`Strange Case of Dr Jekyll and Mr Hyde`}</li>
    </ul>
    <p>{`Robert W. Chambers`}</p>
    <ul>
      <li parentName="ul">{`The King in Yellow`}</li>
    </ul>
    <p>{`Various authors`}</p>
    <ul>
      <li parentName="ul">{`Perry Rhodan`}</li>
    </ul>
    <p>{`Walter Jon Williams`}</p>
    <ul>
      <li parentName="ul">{`Hardwired`}</li>
      <li parentName="ul">{`Solip:System`}</li>
      <li parentName="ul">{`Voice of the Whirlwind`}</li>
    </ul>
    <p>{`William Gibson`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Sprawl Trilogy`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Neuromancer`}</li>
          <li parentName="ul">{`Count Zero`}</li>
          <li parentName="ul">{`Mona Lisa Overdrive`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Bridge Series`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Virtual Light`}</li>
          <li parentName="ul">{`Idoru`}</li>
          <li parentName="ul">{`All Tomorrow's Parties`}</li>
          <li parentName="ul">{`Skinner's Room`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Blue Ant Series`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Pattern Recognition`}</li>
          <li parentName="ul">{`Spook Country`}</li>
          <li parentName="ul">{`Zero History`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Johnny Mnemonic`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Burning Chrome`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`New Rose Hotel`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The Peripheral`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The Difference Engine (w/ Bruce Sterling)`}</p>
      </li>
    </ul>
    <h2>{`Non-Fiction`}</h2>
    <ul>
      <li parentName="ul">{`Grid Systems in Graphic Design`}</li>
      <li parentName="ul">{`The Graphic Artist and His Design Problems`}</li>
      <li parentName="ul">{`History of the Poster`}</li>
      <li parentName="ul">{`James Felici's The Complete Manual of Typography`}</li>
      <li parentName="ul">{`REST und HTTP: Entwicklung und Integration nach dem Architekturstil des Web`}</li>
      <li parentName="ul">{`Kanban`}</li>
      <li parentName="ul">{`Agiles Projektmanagement`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      